@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");

.pagination {
  z-index: 2 !important;
}
* {
  font-family: "Roboto", sans-serif;
  word-spacing: 0.1rem;
}

.tox-statusbar__branding {
  display: none !important;
}

::selection {
  color: #fff;
  background-color: #aa2625;
}
.cursor-pointer {
  cursor: pointer !important;
}

.description-font p {
  font-family: "Raleway", sans-serif !important;
}

.dropdown-menu {
  z-index: 2000 !important;
}

.splide__arrow:disabled {
  display: none !important;
}

.text-overflow {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

#header .dropdown-toggle::after {
  display: none;
}
.dropdown-toggle::after {
  /* position: absolute; */
  top: 18px;
  margin-left: 10px;
}
.dropdown-menubar {
  margin-top: 4px !important;
  font-size: 16px;
  border-radius: 10px !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  width: 350px;
  border: 0 !important;
  /* border-top: 4px solid #911F27!important; */
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.dropdown-menubar::before {
  content: "";
  top: -5px;
  position: absolute;
  height: 5px;
  background-color: #911f27;
  width: 200px;
}
.dropdown-menubar a {
  font-size: 17px;
}
.for-business {
  background-color: transparent;
  padding: 13px;
  white-space: nowrap;
  text-decoration: none !important;
  font-size: 15px;
  font-weight: 500;
}
.for-business:hover {
  background-color: #f3f3f3;
  border-radius: 6px;
}
.breadcrumb {
  background-color: white !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}
/* BreadCrumb - Bootstrap */

h1 {
  font-size: 40px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
button {
  font-weight: 400;
}
body {
  font-family: "Helvetica Neue";
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
.contain-1 {
  position: relative;
  text-align: center;
  color: white;
}
.bottom-right-1 {
  position: absolute;
  top: 73px;
  right: -10px;
  color: white;
  background-color: var(--red);
  padding: 0px 4px;
}
.profile-report {
  width: 60% !important;
}
.signUp-btn {
  border: 1px solid #e8e8e8;
  height: 33px;
  color: #000000 !important;
  padding: 7.5px 16px !important;
  height: 41px;
  border-radius: 5px;
  font-weight: 500;
  white-space: nowrap;
}
.signUp-btn:hover {
  transition: 0.7s;
  background-color: #f3f3f3;
}
.signUp-btn-1 {
  height: 33px;
  white-space: nowrap;
  padding: 7.5px 16px !important;
  height: 41px;
  border-radius: 5px;
  background-color: rgb(145, 20, 39) !important;
  font-weight: 500;
}
.signUp-btn-1:hover {
  background-color: rgb(126, 49, 49) !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: 0.6s;
}
.btn-danger {
  background-color: rgb(145, 20, 39) !important;
  border: 1px solid rgba(145, 20, 39) !important;
  color: white !important;
}
.text-dangers {
  color: rgb(145, 20, 39) !important;
}

.profile {
  width: 55% !important;
}
.profile button {
  text-transform: uppercase;
  padding: 10px 40px;
  width: 30%;
}
.reviews-input {
  width: 500px !important;
}

/* ----------------------------------------------------------------------------------- */
.ringtones-style {
  position: relative;
  text-align: center;
  color: white;
}
.ringtones-style .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ringtones-style .top-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}
.ringtones-style .bottom-left-main {
  position: absolute;
  bottom: 0px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 88%;
}
.ringtones-style .search-left-main {
  position: absolute;
  bottom: -40px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.ringtones-style .bottom-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.whatsapp-hover svg:hover {
  color: #25d366 !important;
}
.popular-baby-names .card-1 {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding: 6px 15px;
}
.popular-baby-names .card-2 {
  padding: 30px !important;
}
.custom-tabs .nav .nav-link {
  border: 0;
  background-color: #fff !important;
  color: #000;
}

.custom-tab .nav .nav-link {
  border: 0;
  background-color: #f8f9fa !important;
  color: #000;
  border-radius: 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Loader animation */

.custom-nav .nav-tabs {
  border: 0 !important;
}
.custom-nav .nav-tabs .nav-link {
  background-color: #fff !important;
  color: #000;
  border: 0 !important;
}
.custom-nav .nav-tabs .nav-link.active {
  /* background-color: var(--danger) !important; */
  border-bottom: 4px solid var(--danger) !important;
  color: var(--danger);
}
/* Loader animation-end */

.clipboard-hover svg:hover {
  color: #c9a860 !important;
}
.mainpage-header .navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}
.statistics-card .nav-tabs {
  border: 0 !important;
}
.statistics-card .nav-tabs .nav-link {
  background-color: #fff !important;
  color: #000;
  border: 0 !important;
}
.statistics-card .nav-tabs .nav-link.active {
  background-color: #911f27 !important;
  color: #fff;
}
.rhap_main-controls .rhap_rewind-button {
  display: none;
}
.rhap_main-controls .rhap_forward-button {
  display: none;
}
.example::-webkit-scrollbar {
  /* display: none; /* Safari and Chrome */
}

/* Listing Page */
.listing .lookingfor .items,
.listing .lookingfor span:first-child {
  padding-bottom: 15px;
  color: #333;
  text-decoration: none;
}
.listing .lookingfor svg {
  margin-right: 0.25rem;
}
.listing .lookingfor svg:hover {
  color: var(--danger);
}
.listing .lookingfor .items:hover {
  padding-bottom: 20px;
  color: var(--danger);
}

/* ------------------------ rect-select ------------------------ */

.css-1s2u09g-control {
  border: none !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-radius: 0 !important;
}
.css-1pahdxg-control {
  border: none !important;
  border-bottom: 1px solid var(--danger) !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.css-1okebmr-indicatorSeparator {
  display: none !important;
}
.css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option {
  cursor: pointer !important;
  background-color: var(--danger) !important;
  color: white !important;
}
.css-26l3qy-menu .css-4ljt47-MenuList .css-1n7v3ny-option:hover {
  background-color: var(--danger) !important;
  color: white !important;
}
.css-26l3qy-menu .css-4ljt47-MenuList .css-9gakcf-option {
  background-color: var(--danger) !important;
}

/* ------------------------ rect-select ------------------------ */

.listing .filters .custom-input {
  width: 100%;
  padding: 5px 0px;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #dee2e6;
  outline: none;
}
.listing .filters .custom-input::placeholder {
  color: #999;
}
/* Listing Page */

/* User Menubar Page */
.container-fluid-1 {
  display: flex;
  padding: 0;
  margin-right: 0 !important;
}
.user-menubar {
  /* position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin: 0 auto;
  z-index: 1; */
  flex-shrink: 0;
  overflow: scroll;
  width: 300px;
  border-right: 1px solid #e1e1e1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 0px);
  padding-top: 1rem;
  align-items: center;
  justify-content: stretch;
  transition: 0.4s;
  position: sticky;
  left: 0;
  top: -20px;
  padding-top: 0;
}
.user-menubar li {
  float: left;
  position: relative;
  width: 100%;
}
.user-menubar .menu-item {
  display: flex;
  text-align: center;
  padding: 25px;
  height: 60px;
  color: #303341;
  width: 100%;
}
.PhoneInput {
  width: 100%;
  border: 1px solid rgb(218, 216, 216);
  padding: 5px 4px;
  border-radius: 4px;
}
.PhoneInputInput {
  border: none;
}
.PhoneInputInput:focus {
  outline: none;
}
.PhoneInput:focus-within {
  outline: 2px solid rgb(54, 182, 232) !important;
}
.user-menubar .menu-item:hover {
  background-color: #efefef;
  text-decoration: none;
  width: 100%;
}
.user-menubar .menu-item svg {
  font-size: 24px;
  margin-top: -5px;
}
.user-menubar .menu-item p {
  font-size: 12px;
  margin-left: 30px;
}
.user-menubar .menu-item.active {
  background-color: #911f27;
  color: #fff !important;
  width: 100%;
}
/* .user-menubar .menu-item.active:after {
  top: 30%;
  left: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-left-color: red;
  border-width: 15px;
  margin-left: -15px;
} */
/* User Menubar Page */

/* Sidebar */
.main-sidebar .bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: black;
  font-weight: 300;
  transition: color 0.2s;
}
.main-sidebar .bm-item:hover {
  color: white;
}
.main-sidebar .bm-burger-button {
  position: fixed;
  width: 150px !important;
  height: 30px;
  right: 20px;
  top: 36px;
}
#react-burger-menu-btn {
  width: 0px !important;
}
.bm-burger-bars {
  display: none;
  background: transparent;
}
.main-sidebar .bm-cross-button {
  height: 24px;
  width: 24px;
}
.main-sidebar .bm-cross {
  background: #bdc3c7;
}
.main-sidebar .bm-overlay {
  top: 0px;
  left: 0px;
}
.main-sidebar .bm-menu {
  margin-top: -32px !important;
  background: #343a40;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.main-sidebar .bm-morph-shape {
  fill: #373a47;
}
.main-sidebar .bm-item-list {
  color: #b8b7ad;
}
.main-sidebar .bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.menu-profile-tag {
  padding: 10px;
  border: 1px solid #ecebeb;
  border-radius: 100%;
  text-decoration: none;
  margin-right: 10px;
  background-color: #ecebeb;
}
.menu-profile-tag svg {
  color: #911f27;
  font-size: 18px;
}
.menu-profile-tag a {
  text-decoration: none;
  color: white;
}
.membership-btn button {
  padding: 10px;
  border: 1px solid rgb(253, 130, 86);
  color: rgb(253, 130, 86);
  text-transform: uppercase;
  font-size: 13px;
}
.business-btn button {
  padding: 10px;
  border: 1px solid rgb(164, 199, 250);
  color: rgb(164, 199, 250);
  text-transform: uppercase;
  font-size: 13px;
}

.sidebar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  /* display: none; */
}
/* Sidebar */

/* Dashboard Account */

.account-form a {
  text-decoration: none;
  color: rgb(0, 119, 255);
}
.account-form a:hover {
  color: rgb(252, 102, 102);
  text-decoration: none;
}
.account-form .save-button {
  border: 2px solid grey;
  border-radius: 40px;
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
}
.account-form .save-button:hover {
  background-color: rgba(145, 20, 39);
  transition: ease-in-out;
  transition: 0.4s;
  border: 2px solid rgba(145, 20, 39);
  color: #fff;
}
.account-form .upgrade-payment button {
  font-size: 13px;
}

/* Dashboard Photos */
/* Photos */
.photo-card .photos-font span {
  padding: 8px 12px;
  margin-right: 4px;
  background: #911f27;
  border-radius: 2px;
}
.photo-card .photos-font span:hover {
  padding: 8px 12px;
  margin-right: 4px;
  background: rgb(125, 20, 39);
  border-radius: 2px;
}
.photo-card .photos-font span svg {
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.photo-card .added-photo span {
  background: #911f27;
  color: white;
  padding: 28px 15px;
}
.photo-card .added-photo {
  border: 1px solid #911f27;
  color: #911f27;
  border-radius: 4px;
}
.photo-card a {
  color: #911f27;
}
.photo-card a:hover {
  color: rgb(252, 102, 102);
  text-decoration: none;
}

/* Dashboard Reviews */

.review-card .rating {
  font-size: 16px;
}
.review-card .remove {
  border: 1px solid #911f27;
  color: #911f27;
  border-radius: 4px;
}
.review-card .remove span {
  background: #911f27;
  color: white;
  padding: 28px 15px;
}
.review-card .review-stars svg {
  color: #ff9529;
}
.review-card a {
  text-decoration: none;
}
.review-card a:hover {
  text-decoration: none;
}
.review-link a {
  color: #000;
}
.review-card .review-profile .profile-tag {
  padding: 10px;
  border: 1px solid #ecebeb;
  border-radius: 100%;
  text-decoration: none;
  margin-right: 10px;
  background-color: #ecebeb;
}
.review-card .review-profile svg {
  color: #911f27;
  font-size: 20px;
}
.review-card .reply-btn {
  border: 1px solid grey;
  border-radius: 40px;
  font-size: 12px;
  padding: 10px 20px;
  text-transform: uppercase;
}
.review-card .reply-btn:hover {
  border: 1px solid rgb(252, 102, 102);
  color: rgb(252, 102, 102);
}
.review-card .review-face a {
  color: grey;
  text-transform: uppercase;
  font-size: 14px;
}
.review-card .review-face svg {
  color: grey !important;
}

/* Dashboard Products */
.products .remove-products {
  border: 1px solid #911f27;
  color: #911f27;
  border-radius: 4px;
}
.products .remove-products span {
  background: #911f27;
  color: white;
  padding: 28px 15px;
}

.products .product-icons span {
  padding: 6px 12px;
  margin-right: 4px;
  background: #911f27;
  border-radius: 2px;
}
.products .product-icons span:hover {
  padding: 6px 12px;
  margin-right: 4px;
  background: rgb(115, 27, 39);
  border-radius: 2px;
}
.products .product-icons span svg {
  color: white;
  font-size: 13px;
  font-weight: bold;
}

/* Dashboard Employees */
.employee .product-icons span {
  padding: 6px 12px;
  margin-right: 4px;
  background: #911f27;
  border-radius: 2px;
}
.employee .product-icons span:hover {
  padding: 6px 12px;
  margin-right: 4px;
  background: black;
  border-radius: 2px;
}
.employee .product-icons span svg {
  color: white;
  font-size: 13px;
  font-weight: bold;
}
.employee .add-employee {
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;
}

/* Q/A */

.q-a .q-a1 {
  font-size: 16px;
}
.q-a .remove {
  border: 1px solid #911f27;
  color: #911f27;
  border-radius: 4px;
}
.q-a .remove span {
  background: #911f27;
  color: white;
  padding: 28px 15px;
}
.jobs .job-icons span {
  padding: 6px 12px;
  margin-right: 4px;
  background: #911f27;
  border-radius: 2px;
}
.jobs .job-icons span:hover {
  padding: 6px 12px;
  margin-right: 4px;
  background: black;
  border-radius: 2px;
}
.jobs .job-icons span svg {
  color: white;
  font-size: 13px;
  font-weight: bold;
}

/* Dashboard Inbox */
.inbox .inbox1 {
  font-size: 16px;
}

/* Dashboard share */
.share .share1 {
  font-size: 16px;
}
.share .share-imp {
  padding: 20px;
  border-left: 10px solid rgba(145, 20, 39);
  background-color: rgb(252, 201, 210);
  color: rgba(145, 20, 39);
}
.share .share-btn {
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 14px;
}

/* Dashboard Statistics */
.stat .nav-item .nav-link {
  background-color: rgba(248, 241, 241, 0.925);
  padding: 15px 30px;
  border: 1px solid rgb(230, 225, 225);
  border-radius: 0;
}
.stat .nav-item .nav-link a {
  color: black;
}

/* Dashboard upgrade */
.upgrade h3 {
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
  color: #414141 !important;
}
.upgrade .price_discount {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin-top: -22px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  background-color: var(--danger);
}
.discount h3 {
  color: #fff !important;
}
.discount div {
  color: #fff !important;
}
.upgrade-text {
  font-size: 20px;
}
.upgrade .price_discount p {
  left: 9px;
  top: 12px;
  color: #fff;
  font-weight: 300;
  position: absolute;
}
.upgrade a {
  text-decoration: none !important;
  color: var(--danger);
}
.upgrade p span {
  font-size: 40px;
  color: #414141;
}
.upgrade .btn-upgrade {
  background-color: rgb(165, 30, 44);
  padding: 10px 50px;
  color: #fff;
  text-transform: uppercase;
  border: none;
}
.upgrade .card-upgrade {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition: all 0.3s ease-in-out;
}
.upgrade .card-upgrade:hover {
  transform: translate(0, -15px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}
/* why updgrade */
.card-number {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  position: relative;
}
.card-number p {
  left: 20px;
  top: 13px;
  font-weight: 300;
  position: absolute;
}
.exclaimation {
  /* height: 100%!important; */
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  background: #f38269 url("https://www.businesslist.pk/img/site/alert.png?v=3") center center no-repeat;
}

@media only screen and (max-width: 325px) {
  .card-number {
    width: 35px !important;
    height: 25px !important;
    border-radius: 100px;
  }
  .card-number p {
    left: 8px !important;
    top: 0px !important;
  }
}
@media only screen and (max-width: 600px) {
  .whyupgrade-card h2 a {
    font-weight: 700;
    font-size: 18px !important;
    line-height: 20px;

    text-decoration: none !important;
  }
  .card-number {
    width: 40px;
    height: 35px;
    border-radius: 50px;
  }
  .card-number p {
    left: 12px;
    top: 4px;
  }
}
.whyupgrade-card h2 a {
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;

  text-decoration: none !important;
}
.whyupgrade-card a {
  text-decoration: none !important;
}

.whyupgrade-card .upgrade-btn {
  background-color: var(--danger);
  color: #fff;
}
/* Ringtones Download */

.card-ringtones {
  width: 100%;
  height: 150px;
  background-image: linear-gradient(to right, rgb(131, 219, 241), rgb(232, 137, 245));
}
.ringtons-font svg {
  font-size: 25px;
}
.ringtones-btn button {
  background-color: rgb(56, 50, 50);
  color: white;
  margin-right: 10px;
}
.ringtones-btn button:hover {
  background-color: black;
  color: white;
}
.card-tabs {
  background-color: black;
  height: 350px;
  border-radius: 7px;
}
.ringtones-more-btn button {
  padding: 10px 30px;
  color: white;
  background-color: rgb(148, 84, 190);
}
.ringtones-more-btn button:hover {
  color: white;
  background-color: rgb(127, 49, 179);
}
.ringtones-tab {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}
.ringtones-tab .nav-item .nav-link {
  padding: 15px;
  border: none;
}
.ringtones-tab .nav-item .nav-link :active {
  border-bottom: 4px solid rgb(127, 49, 179);
  color: rgb(127, 49, 179);
  font-weight: bolder;
}

/* Main Ringtones*/
.main-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.1) 0px 3px 40px;
}

/*************************************** Prices > GoldPrices ****************************************/
.goldPrices {
  /* background-color: #d3d6e4d0; */
}
.goldPrices .card {
  border-radius: 10px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px!important; */
}
.goldPrices ul li {
  list-style: none;
  border-bottom: 1px solid #d3d6e4d0;
  padding: 15px;
}
.goldPrices table thead th {
  background-color: rgb(200, 35, 51);
}

/****************************************** Prices > PetrolPrices *************************************************/
.petrolPrices h3 {
  color: rgb(200, 35, 51);
}
.petrolPrices table th:first-child {
  border-radius: 5px 0 0 5px;
}
.petrolPrices table th:last-child {
  border-radius: 0 5px 5px 0;
}
.petrolPrices table thead th {
  background-color: rgb(200, 35, 51);
  color: white;
  font-weight: 800;
}
.petrolPrices table tbody tr td {
  border-bottom: 1px solid rgb(200, 35, 51);
}
.petrolPrices button {
  font-size: 25px;
}
.petrolPrices hr {
  height: 1px;
  background-color: black;
}
.petrolPrices .more-business h3 {
  color: rgb(200, 35, 51);
  font-size: 30px !important;
  width: 100%;
  border-bottom: 0px solid rgb(200, 35, 51);

  margin-bottom: 30px;
}

/*******************   Mobile Prices  **************************/
.mobilePrices {
  background-color: #f6f6f6;
}
.mobilePrices input {
  border: 1px solid rgb(200, 35, 51);
}
.mobilePrices .search-btns button {
  background-color: rgb(200, 35, 51);
  color: white;
  padding: 5px 60px;
}

.mobilePrices .search-btns button:hover {
  background-color: rgb(190, 4, 23);
  color: white;
  border: 1px solid rgb(200, 35, 51);
  transition: all ease 0.5s;
}
.mobilePrices .view-all button {
  background-color: rgb(200, 35, 51);
  color: white;
  padding: 5px 30px;
}
.mobilePrices .view-all button:hover {
  background-color: rgb(190, 4, 23);
  color: white;
  padding: 5px 30px;
}
.mobilePrices .card-Mobiles {
  background-color: #e9e9e9;
  border: 0;
  border-radius: 8px;
}
.mobilePrices .card-Mobiles:hover {
  background-color: #efefef;
}
.mobilePrices .card-prices {
  background: white;
  border-radius: 3px;
}
.mobilePrices .card-prices {
  padding: 20px;
  width: 100%;
  border: 0;
  border-bottom: 4px solid;
  border-image: linear-gradient(to right, rgb(200, 35, 51) 0%, rgb(200, 35, 51) 20%) 1 stretch repeat; /* W3C */
}
.mobilePrices a {
  color: #000;
}
.mobilePrices a:hover {
  text-decoration: none;
  color: #000;
}

/******************************************** Mobile Compare ***************************************/

.more-business h3 {
  color: rgb(200, 35, 51);
  border-bottom: 2px solid rgb(200, 35, 51);
  width: 280px;
}

.latest-mobiles {
}
.latest-mobiles ul li {
  list-style: none;
  padding: 10px 5px;
}
.latest-mobiles ul li svg {
  color: #cfcfcf;
  margin-top: 5px;
}
.latest-mobiles .card-1 .card-header {
  background-color: #911f27;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.latest-mobiles ul li a {
  text-decoration: none;
  color: rgb(27, 25, 25);
}
.latest-mobiles ul li:hover {
  background-color: #efefef;
  transition: all ease 0.4s;
  border-radius: 0px;
  margin: 0;
}

/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.30); */
.btn-custom {
  font-size: 14px !important;
  padding: 0.5rem 2rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #911f27 !important;
  border: none !important;
  border-radius: 4px;
  text-transform: uppercase;
}

.btn-custom:hover {
  text-decoration: none;
  color: #fff;
}
.ctm-shadow {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.card-border {
  border: 1px solid #c3c3c3;
  padding: 2px 1px 4px 4px;
  border-radius: 100%;
  color: #000 !important;
}
.btn-custom:active {
  box-shadow: none !important;
  transform: translateY(4px) !important;
}
.latest-mobiles .card-2 .card-header {
  background-color: #911f27;
  color: #fff;
  border-radius: 5px;
}
.latest-mobiles .card-2 {
  border-top: 1px solid #c3c3c3;
  border-left: 1px solid #c3c3c3;
}
.card-heading h4 {
  color: rgb(200, 35, 51);
  font-weight: 1000;
}
.card-heading a {
  color: #000;
}
.card-heading a:hover {
  text-decoration: none;
  color: black;
}

/************************************  inside-mobile  **************************/
.price-paragraph {
  font-size: 12px;
}
.price-paragraph p svg {
  font-size: 12px;
}
.icons-inside p {
  font-size: 14px;
}
.specs-hover {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.specs-hover:hover {
  /* width: auto!important; */
  white-space: normal !important;
  text-overflow: none;
  overflow: visible;
}
.icons-inside p span {
  color: #a59f9f;
}
.inside-details .card {
  /* background: #f8f9fa; */
  border: 0;
  border-radius: 8px;
}
.inside-details h3 {
  font-weight: bolder;
}
.inside-details h5 {
  font-weight: bolder;
  color: rgb(200, 35, 51);
}
.inside-heading {
  background: #e3e3e3;
  padding: 5px;
}
.inside-heading h6 {
  text-transform: uppercase;
  font-weight: bold;
}

.inside-heading h6 svg {
  font-size: 30px;
}
.inside-para p {
  padding: 0;
  margin: 5px;
}
.inside-para .col-sm-4 {
  color: #a59f9f;
  font-weight: bold;
}

/********************************************* Baby Names ****************************************************/
.baby-names .tab-content .badge {
  background-color: rgb(200, 35, 51);
  border-radius: 100%;
  font-size: 18px;
  color: #fff;
}
.baby-names .tab-content .card {
  background-color: #e6dfdf;
  border-radius: 8px;
}
.baby-names .tab-content button {
  color: rgb(200, 35, 51);
  font-weight: bold;
}
.baby-names .tab-content button:focus {
  box-shadow: none;
  color: var(--danger);
}
.baby-names .tab-content .jump-to {
  color: rgb(200, 35, 51);
  font-weight: bold;
  border-top: dotted;
  border-bottom: dotted;
}
.baby-names .tab-content .baby-search-btn {
  background-color: rgb(200, 35, 51);
  color: #fff;
  padding: 10px 50px;
  margin-top: 20px;
}
.baby-names .tab-content .baby-search-btn:hover {
  background-color: #fff;
  color: var(--danger);
  border-color: var(--danger);
}
.baby-names .tab-content .baby-list {
  border-bottom: 1px solid #c3c3c3;
  padding: 10px;
}

.baby-names .tab-content .top-names svg {
  color: rgb(177, 7, 24);
}
.baby-names .tab-content .section-names .card {
  background-color: rgb(200, 35, 51);
  color: white;
}
.baby-names .tab-content .section-names .card .col-sm-8 {
  background-color: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 10px;
}
.top-names-list .card {
  color: white;
}
.top-names-list .names-listing .badge {
  background-color: rgb(177, 7, 24);
  padding: 10px 13px;
  font-size: 10px;
}
.top-names-list .names-listing:hover {
  background-color: #ececec;
}
.top-names-list .justify-content-between {
  border-bottom: 1px solid #bdbaba;
}
.top-names-list a {
  text-decoration: none !important ;
}

/******************************** Nav tabs **************************************/
.baby-names .baby-tabs .nav-item .nav-link {
  padding: 15px 20px;
  border: 0;
}
.baby-names .baby-tabs .nav-item .nav-link.active {
  border-bottom: 4px solid rgb(200, 35, 51);
}
.baby-names .baby-tabs .nav-item .nav-link {
  color: #000;
}

.baby-names .form-control:focus {
  background-color: transparent;
  border: 0px solid !important;
  color: #ccc !important;
  outline: none !important;
  box-shadow: none !important;
}
/******************************** SMS **************************************/
.sms-outside .card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.sms-outside .sms-card {
  border: 1px solid var(--danger);
  border-radius: 20px;
  color: #333;
  font-size: 20px;
}
.sms-outside .sms-card:hover {
  background-color: rgb(200, 35, 51);
  color: #fff;
  transition: all ease-in-out 0.3s;
}

/******************************** SMS inside **************************************/
.sms-inside .card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.sms-inside .card p {
  /* border-bottom: 1px solid #c3c3c3; */
  padding: 5px;
}
.sms-inside .birthday-sms svg {
  font-size: 25px;
  color: #c3c3c3;
}
.sms-inside .birthday-sms p {
  font-style: italic;
  font-size: 18px;
}
.business-info .dropdown-toggle::after {
  margin-left: 180px;
}
.business-profile {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px inset;
}

.social-media-business .twitter {
  background-color: rgb(0, 200, 255);
  color: white;
  font-size: 16px !important;
  border-radius: 100px;
  padding: 6px 8px;
}
.social-media-business .fb {
  font-size: 16px !important;
  border-radius: 100px;
  background-color: #157af6;
  color: white;
  padding: 6px 8px;
}

.business-profile button {
  background-color: rgb(200, 35, 51);
  padding: 7px 10px;
  color: #fff;
  border-radius: 0;
}
.business-profile button:hover {
  background-color: rgb(177, 7, 24);
  color: #fff;
}
.tags p {
  border: 1px solid #c3c3c3;
  width: auto;
  padding: 7px;
}
.tags {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.rating-star svg {
  color: rgb(255, 230, 0);
}
.b-social-media svg {
  color: rgb(65, 65, 247);
}
.b-social-media svg:hover {
  color: rgb(90, 90, 253);
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .header-1 .hidden-element {
    display: none;
  }
  .hide {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .reviews-input {
    width: 100% !important;
  }
  .hidden-element {
    display: none !important;
  }
  .mainpage-header .sign-up-btn {
    width: 15% !important;
  }
  .buttonStyle {
    width: 100%;
  }
  .profile-report {
    width: 100% !important;
  }

  .profile {
    width: 100% !important;
  }
  .profile button {
    text-transform: uppercase;
    padding: 10px 40px;
    width: 30%;
  }
}
/*--------------------------------------------------- mobile web view-------------------------- */

/* ----------------------------------------------------- Mobile Responsive -------------------------------------------------------- */

@media screen and (max-width: 425px) {
  /* ------------------------------------------------- Home Page --------------------------------------------------------- */
  .reviews-input {
    width: 100% !important;
  }
  .mainpage-header .search-bar {
    margin-top: 20px !important;
  }
  .mainpage-header .creditStyle {
    margin-top: 20px !important;
  }
  .input-hidden {
    display: none;
  }
  .buttonStyle {
    width: 100%;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 22px !important;
  }
  .mainpage-header .sign-up-btn {
    width: 40% !important;
    text-align: center;
  }
  .profile-report {
    width: 100% !important;
  }

  .profile {
    width: 100% !important;
  }
  .profile button {
    text-transform: uppercase;
    padding: 10px 40px;
    width: 70%;
  }
}

.container-width {
  padding: 0px 70px;
}

@media (max-width: 1300px) {
  .container-width {
    padding: 0 20px;
  }
}

.bg-svg-generator h2 {
  margin-top: -80px;
  font-size: 25px;
}
.bg-svg-generator .bg-img-search-mobile {
  display: block;
  position: relative;
  top: -24px;
  right: -24px;
  height: 100px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1424 200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(200, 35, 51, 1)" d="M 0 0 C 361.5 0 361.5 110 723 110 L 723 110 L 723 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(200, 35, 51, 1)" d="M 722 110 C 1081 110 1081 0 1440 0 L 1440 0 L 1440 0 L 722 0 Z" stroke-width="0"></path> </svg>');
  background-size: cover;
  background-repeat: no-repeat;
}
.card-mobile-price {
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 15px;
  border-bottom: 3px solid rgb(190, 4, 23);
}

@media (max-width: 1300px) {
  .for-business {
    padding: 10px;
    font-size: 14px;
    color: black;
  }
}

.WAMuiChipInput-chip-16 {
  margin: 0px !important;
}
