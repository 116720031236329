#desktop-search-bar {
  .auto-complete {
    div {
      // &::-webkit-scrollbar {
      //   -webkit-appearance: none;
      //   background: rgba(0, 0, 0, 0.04);
      //   border-bottom-right-radius: 0px;
      // }

      // &::-webkit-scrollbar:vertical {
      //   width: 5px;
      // }

      // &::-webkit-scrollbar:horizontal {
      //   height: 0px;
      // }

      // &::-webkit-scrollbar-thumb {
      //   border-radius: 8px;
      //   border: 2px solid var(--red); /* should match background, can't be transparent */
      //   background-color: var(--red);
      // }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb:vertical {
        margin: 5px;
        background-color: #999;
        -webkit-border-radius: 5px;
      }

      &::-webkit-scrollbar-button:start:decrement,
      &::-webkit-scrollbar-button:end:increment {
        height: 5px;
        display: block;
      }
    }
  }
}
