.header {
  .auto-complete {
    div {
      // &::-webkit-scrollbar {
      //   -webkit-appearance: none;
      //   background: rgba(0, 0, 0, 0.04);
      //   border-bottom-right-radius: 0px;
      // }

      // &::-webkit-scrollbar:vertical {
      //   width: 5px;
      // }

      // &::-webkit-scrollbar:horizontal {
      //   height: 0px;
      // }

      // &::-webkit-scrollbar-thumb {
      //   border-radius: 8px;
      //   border: 2px solid green; /* should match background, can't be transparent */
      //   background-color: green;
      // }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #707070;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #355cc9;
      }
      &::-webkit-overflow-scrolling:touch {
        background-color: red;
        width: 5px;
      }
    }
  }
  .bars {
    position: relative;
    .dot {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: var(--red);
      right: 0;
      top: 5px;
      border: 2px solid white;
      transform: scale(2.2);
      border-radius: 100px;
    }
    .bar-1 {
      width: 32px !important;
      height: 4px;
      background-color: var(--red);
      border-radius: 100px;
      margin-top: 9px;
    }
    .bar-2 {
      width: 32px !important;
      height: 4px;
      background-color: var(--red);
      border-radius: 100px;
      margin-top: 9px;
    }
    .bar-3 {
      margin-top: 9px;
      width: 32px !important;
      height: 4px;
      background-color: var(--red);
      border-radius: 100px;
    }
  }
}

.custom-search {
  height: 100vh !important;
  background-color: white;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  .custom-input {
    background-color: #f8f9fa;
    width: 230px;
    &:focus {
      box-shadow: none !important;
      background-color: #f8f9fa;
    }
  }
}

#search-mobile-modal {
  margin: 0px !important;
}
