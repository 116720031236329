#left-sidebar {
  z-index: 999999 !important;
  .bm-overlay {
    top: 0px;
    left: 0px;
    height: 100% !important;
  }
  .bm-cross-button {
    height: 24px;
    width: 24px;
    margin-top: 0px;
    margin-right: 20px;
  }
  .bm-cross {
    background: #bdc3c7;
  }
  .bm-menu-wrap {
    background-color: white !important;
    position: fixed;
    top: 0px;
    left: 0px;
    // padding-bottom: 56px !important;
  }
  .bm-item {
    outline: none;
  }
  .bm-item-list {
    overflow-y: scroll;
  }

  .mobile-menu {
    padding: 1rem;
  }
}

.header {
  padding: 14px 14px !important;
  background-color: #fff;
  display: flex;
  position: fixed;
  z-index: 3;
  margin-top: 0px;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  .back {
    color: var(--red);
    align-self: center;
    font-size: 35px;
  }
  .search {
    background-color: #faf9f6;
    border-radius: 10px;
    padding: 5px 8px;
    .search-bar {
      background-color: transparent !important;
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .form-control {
    border: none;
  }
  .search-icon {
    color: #000;
    align-self: center;
    font-size: 20px;
    font-weight: 700 !important;
  }
  form {
    width: 100%;
    padding: 0 8px;
  }
}

#footer {
  .footer {
    box-shadow: 1px 1px 5px #d3d2d2;
    background-color: #fff;
    text-align: center;
    padding: 5px 10px;
    bottom: 0 !important;
    position: fixed;
    width: 100%;
    a {
      text-decoration: none;
      color: #000;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .footer-icon {
      font-size: 20px;
    }
    .add {
      position: relative;
      margin-top: -25px;
      padding: 6px 8px 6px 8px;
      align-items: center;
      align-content: center;
      border-radius: 20px;
      border: 3px solid var(--red);
    }
  }
}

#filters {
  .type {
    display: flex;
    border-radius: 20px;
    border: 2px solid gray;
    padding: 3px 6px;
    justify-content: center;
    p {
      font-size: 13px;
    }
  }
}
