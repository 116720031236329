* {
  font-family: Camphor, Open Sans, Segoe UI, sans-serif;
}
:root {
  --primaryColor: #ec7975;
  --red: #912827;
}
#left-sidebar {
  p {
    font-size: 15px;
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 17px;
    left: 8px;
    top: 25px;
    color: var(--red) !important;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background-color: var(--red);
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    margin-top: 55px;
    margin-right: 20px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
  }

  /* General sidebar styles */
  .bm-menu {
    // margin-top: 55px;
    // padding: 0em 1.5em 0;
    font-size: 1.15em;
    // margin-left: -40px;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    height: 0% !important;
  }
}

#right-sidebar {
  p {
    font-size: 15px;
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 17px;
    right: 8px;
    top: 25px;
    color: var(--red) !important;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background-color: var(--red);
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    margin-top: 55px;
    margin-right: 25px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
  }

  /* General sidebar styles */
  .bm-menu {
    margin-top: 60px;
    padding: 0em 1.5em 0;
    font-size: 1.15em;
    margin-left: 0px;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    height: 0% !important;
  }
}

// ---------------------------- Header ----------------------

.header {
  padding: 14px 14px !important;
  background-color: #fff;
  display: flex;
  position: fixed;
  z-index: 3;
  margin-top: 0px;
  justify-content: space-between;
  box-shadow: 1px 1px 5px #d3d2d2;
  .back {
    color: var(--red);
    align-self: center;
    font-size: 35px;
  }
  .search {
    background-color: #faf9f6;
    border-radius: 10px;
    padding: 5px 8px;
    .search-bar {
      background-color: transparent !important;
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .form-control {
    border: none;
  }
  .search-icon {
    color: #000;
    align-self: center;
    font-size: 20px;
    font-weight: 700 !important;
  }
  form {
    width: 100%;
    padding: 0 8px;
  }
}

// -------------------------- END ----------------------------

// ------------------------ Footer ----------------------------------
#footer {
  .footer {
    box-shadow: 1px 1px 5px #d3d2d2;
    background-color: #fff;
    text-align: center;
    padding: 5px 10px;
    bottom: 0 !important;
    position: fixed;
    width: 100%;
    a {
      text-decoration: none;
      color: #000;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .footer-icon {
      font-size: 20px;
    }
    .add {
      position: relative;
      margin-top: -25px;
      padding: 6px 8px 6px 8px;
      align-items: center;
      align-content: center;
      border-radius: 20px;
      border: 3px solid var(--red);
    }
  }
}

// ============================= Filters ===========================
#filters {
  .type {
    display: flex;
    border-radius: 20px;
    border: 2px solid gray;
    padding: 3px 6px;
    justify-content: center;
    p {
      font-size: 13px;
    }
  }
}

// footer mobile
#footer-mobile {
  margin-bottom: 90px;
  .footer-input {
    border-radius: 8px;
    border: 1px solid #912827;
    height: 48px;
    &:focus {
      box-shadow: none !important;
      border: 1px solid #912827 !important;
    }
  }
  .sub-button {
    background-color: #912827;
    color: white;
    border-radius: 8px;
    padding: 10px;
    margin-left: 5px;
  }
  .social-icon {
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 6px 12px;
    margin-right: 13px;
    font-size: 17px;
  }
  .stores-img {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
  .brand-logo {
    // width: 60%;
    height: 50px;
    object-fit: contain;
    margin-left: 10px;
  }
}
