@media (max-width: 480px) {
  #menu {
    .bm-menu {
      margin-top: 0px !important;
    }
    .bm-overlay {
      top: 0;
    }
    .bm-menu-wrap {
      top: 0;
    }
  }
}
